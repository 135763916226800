export default function ContactPage(){
    return(
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--content-dark)'
        }}>
            <h1 className='change-font' style={{paddingTop: 60, color: 'white'}}>Under construction</h1>
        </div>
    )
}
